import CommitteesPage from 'components/communities/CommitteesPage';
import HomePage from 'components/home/HomePage';
import MembershipPage from 'components/subscriptions/MembershipPage';
import ProfilePage from 'components/profile/ProfilePage';
import SettingsPage from 'components/settings/SettingsPage';
import React, { useCallback, useContext, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes, {
  editApplicationRoutes,
  myEventsRoutes,
  applicationsRoutes,
  groupPaymentRoutes,
  profileRoutes,
  editApplicationReviewRoutes,
  subscriptionRoutes,
  companyPaymentsRoutes,
  partnerRoutes,
  companyRoutes,
  customerCompanyRoutes,
} from 'store/configs/Routes';
import CompanyPaymentsPage from 'components/company/CompanyPaymentsPage';
import PaymentsPage from 'components/payments/PaymentsPage';
import NotFoundPage from 'components/layout/NotFoundPage';
import EnrollMembershipPage from 'components/subscriptions/EnrollMembershipPage';
import SiteModule from 'store/enums/SiteModule';
import { ConfigContext } from 'components/ConfigGuard';
import ApplicationsPage from 'components/applications/ApplicationsPage';
import SelectApplicationTypePage from 'components/applications/SelectApplicationTypePage';
import ApplicationPage from 'components/applications/ApplicationPage';
import EventRegistrationsPage from 'components/events/EventRegistrationsPage';
import ApplicationReviewPage from 'components/applications/ApplicationReviewPage';
import ChaptersPage from 'components/communities/ChaptersPage';
import SubscriptionsPage from 'components/subscriptions/SubscriptionsPage';
import GroupPaymentsPage from 'components/payments/GroupPaymentsPage';
import { getDefaultHomeRoute, getPersonalPaymentsRoutes } from 'util/Route';
import CompanyPage from 'components/company/CompanyPage';
import { UserContext } from './UserGuard';
import SupportPage from 'components/support/SupportPage';
import AddressesPage from 'components/addresses/AddressesPage';
import DonationPage from 'components/donation/ManageDonationPage';
import PartnerPage from 'components/partner/PartnerPage';
import PublicCommitteeDetailsPage from 'components/communities/PublicCommitteeDetailsPage';
import EngagementReportPage from 'components/engagement/EngagementReportPage';
import { isModuleAvailable } from 'util/User';
import StayConnectedPage from 'components/stayconnected/StayConnectedPage/StayConnectedPage';
import IndividualDetailsPage from 'components/directory/IndividualDetailsPage';
import CompanyDetailsPage from 'components/directory/CompanyDetailsPage/CompanyDetailsPage';

const AppProtectedRoutes: React.FunctionComponent = () => {
  const {
    enabledModules = [],
    sidebar,
    modulesSettings: {
      settings: { enableEmailNotifications, enableAccountSettings },
      personalPayments: { enablePayInvoices },
    },
  } = useContext(ConfigContext);
  const paymentsEnabled: boolean = useMemo(
    () => !!enabledModules.find((item) => item === SiteModule.Payments),
    [enabledModules]
  );
  const { functions, userPermissions } = useContext(UserContext);
  const checkModuleAccess = useCallback(
    (module: SiteModule) => isModuleAvailable(module, functions, userPermissions),
    [functions, userPermissions]
  );
  const appRoutes: React.ReactNode[] = useMemo(() => {
    let result: React.ReactNode[] = [
      <Route key={'default'} path={'/'} exact={true}>
        <Redirect to={getDefaultHomeRoute(enabledModules)} />
      </Route>,
    ];

    if (enabledModules.includes(SiteModule.Home) && checkModuleAccess(SiteModule.Home)) {
      result = [
        ...result,
        <Route key={'home'} path={routes.home} exact={true}>
          <HomePage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.Profile) && checkModuleAccess(SiteModule.Profile)) {
      sidebar.forEach((item) => {
        if (item.type === SiteModule.Profile) {
          result = [
            ...result,
            <Route key={'profile'} path={profileRoutes} exact={true}>
              <ProfilePage />
            </Route>,
          ];
        }
      });
    }

    if (enabledModules.includes(SiteModule.PublicCommittees) && checkModuleAccess(SiteModule.PublicCommittees)) {
      result = [
        ...result,
        <Route key={'public-committee-details'} path={`${routes.publicCommitteesDetails}/:id`} exact={true}>
          <PublicCommitteeDetailsPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.MemberDirectory) && checkModuleAccess(SiteModule.MemberDirectory)) {
      result = [
        ...result,
        <Route key={'individual-details'} path={`${routes.customerIndividual}/:id`} exact={true}>
          <IndividualDetailsPage />
        </Route>,
        <Route key={'company-details'} path={customerCompanyRoutes} exact={true}>
          <CompanyDetailsPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.StayConnected) && checkModuleAccess(SiteModule.StayConnected)) {
      result = [
        ...result,
        <Route key={'stay-connected'} path={routes.communicationsPreferences} exact={true}>
          <StayConnectedPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Support) && checkModuleAccess(SiteModule.Support)) {
      result = [
        ...result,
        <Route key={'support'} path={routes.support} exact={true}>
          <SupportPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.DonationsPremium) && checkModuleAccess(SiteModule.DonationsPremium)) {
      result = [
        ...result,
        <Route key={'manage-donations'} path={routes.manageDonations} exact={true}>
          <DonationPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Partner) && checkModuleAccess(SiteModule.Partner)) {
      result = [
        ...result,
        <Route key={'partner'} path={partnerRoutes} exact={true}>
          <PartnerPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Addresses) && checkModuleAccess(SiteModule.Addresses)) {
      result = [
        ...result,
        <Route key={'addresses'} path={routes.addresses} exact={true}>
          <AddressesPage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.Committees) && checkModuleAccess(SiteModule.Committees)) {
      result = [
        ...result,
        <Route key={'committees'} path={routes.committees} exact={true}>
          <CommitteesPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Chapters) && checkModuleAccess(SiteModule.Chapters)) {
      result = [
        ...result,
        <Route key={'chapters'} path={routes.chapters} exact={true}>
          <ChaptersPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Membership) && checkModuleAccess(SiteModule.Membership)) {
      result = [
        ...result,
        <Route key={'membership'} path={routes.membership} exact={true}>
          <MembershipPage />
        </Route>,
        <Route key={'membership-pay'} path={routes.enrollMembership} exact={true}>
          <EnrollMembershipPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Subscriptions) && checkModuleAccess(SiteModule.Subscriptions)) {
      result = [
        ...result,
        <Route key={'subscriptions'} path={subscriptionRoutes} exact={true}>
          <SubscriptionsPage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.MyEvents) && checkModuleAccess(SiteModule.MyEvents)) {
      result = [
        ...result,
        <Route key={'education'} path={myEventsRoutes} exact={true}>
          <EventRegistrationsPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Applications) && checkModuleAccess(SiteModule.Applications)) {
      result = [
        ...result,
        <Route key={'applications'} path={applicationsRoutes} exact={true}>
          <ApplicationsPage />
        </Route>,
        <Route key={'select-application-type'} path={routes.createApplication} exact={true}>
          <SelectApplicationTypePage />
        </Route>,
        <Route key={'edit-application'} path={editApplicationRoutes} exact={true}>
          <ApplicationPage />
        </Route>,
        <Route key={'edit-application-review'} path={editApplicationReviewRoutes} exact={true}>
          <ApplicationReviewPage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.PersonalPayments) && checkModuleAccess(SiteModule.PersonalPayments)) {
      const personalRoutes: string[] = getPersonalPaymentsRoutes(enablePayInvoices);

      result = [
        ...result,
        <Route key={'payments'} path={personalRoutes} exact={true}>
          <PaymentsPage />
        </Route>,
        <Route key={'payment-details'} path={'/payments/details/:invoiceId'}>
          {({ match }) => <Redirect to={`${routes.personalPaymentDetails}/${match ? match.params.invoiceId : ''}`} />}
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.GroupPayments) && checkModuleAccess(SiteModule.GroupPayments)) {
      result = [
        ...result,
        <Route key={'group-payments'} path={groupPaymentRoutes} exact={true}>
          <GroupPaymentsPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Settings) && checkModuleAccess(SiteModule.Settings)) {
      const path: string[] = [routes.settings];
      enableEmailNotifications && path.push(routes.emailNotificationsSettings);
      enableAccountSettings && path.push(routes.settingsAccount);
      paymentsEnabled && path.push(routes.paymentMethodsSettings);
      result = [
        ...result,
        <Route key={'settings'} path={path} exact={true}>
          <SettingsPage />
        </Route>,
      ];
    }
    if (enabledModules.includes(SiteModule.Company) && checkModuleAccess(SiteModule.Company)) {
      result = [
        ...result,
        <Route key={'company'} path={companyRoutes} exact={true}>
          <CompanyPage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.CompanyPayments) && checkModuleAccess(SiteModule.CompanyPayments)) {
      result = [
        ...result,
        <Route key={'company-payments'} path={companyPaymentsRoutes} exact={true}>
          <CompanyPaymentsPage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.EngagementReport) && checkModuleAccess(SiteModule.EngagementReport)) {
      result = [
        ...result,
        <Route key={'engagement-report'} path={routes.engagementReport} exact={true}>
          <EngagementReportPage />
        </Route>,
      ];
    }

    return [
      ...result,
      <Route key={'not-found'} path={'*'} exact={true}>
        <NotFoundPage />
      </Route>,
    ];
  }, [
    enabledModules,
    checkModuleAccess,
    sidebar,
    enablePayInvoices,
    enableEmailNotifications,
    enableAccountSettings,
    paymentsEnabled,
  ]);

  return <Switch>{appRoutes}</Switch>;
};
export default AppProtectedRoutes;
