import { Button, Divider, Grid, Link } from '@mui/material';
import React from 'react';
import Card from 'components/shared/Card';
import { MemberDirectoryCustomer } from 'store/types/MemberDirectory';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import StatusLabel from 'components/shared/StatusLabel';
import { getEmailLink, getPhoneLink, getUserFullName } from 'util/Format';
import { Email, Language } from '@mui/icons-material';
import Phone from '@mui/icons-material/Phone';
import Domain from '@mui/icons-material/Domain';
import { getHashRouteUrl } from 'util/Route';
import routes from 'store/configs/Routes';
import { isCanadaSelected, isUSSelected } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Website from 'components/shared/Website';
import MemberDirectoryCustomerType from 'store/enums/MemberDirectoryCustomerType';

import styles from './MemberListItem.module.scss';

interface MemberListItemProps {
  data: MemberDirectoryCustomer;
}

const getDetailsLink = (customerType: MemberDirectoryCustomerType, id: string): string => {
  let link = '';

  switch (customerType) {
    case MemberDirectoryCustomerType.Individual:
      link = routes.customerIndividual;
      break;
    case MemberDirectoryCustomerType.Chapter:
      link = routes.customerChapter;
      break;
    case MemberDirectoryCustomerType.Company:
      link = routes.customerCompany;
      break;
  }

  return `${getHashRouteUrl(link)}/${id}`;
};

const MemberListItem: React.FunctionComponent<MemberListItemProps> = ({ data }) => {
  const {
    companyName,
    address,
    email,
    firstName,
    lastName,
    suffix,
    customerType,
    id,
    phone,
    website,
    parentCompanyId,
    title,
    isContactInformationPrivate,
    marketSegments,
  } = data;

  return (
    <Card className={styles.card} contentClassName={styles.content} spinnerClassName={styles.spinner}>
      <Grid {...defaultGridContainerProps} spacing={2} justifyContent={'flex-end'}>
        <Grid {...defaultGridItemProps} className={styles.title}>
          <span className={styles.name}>
            {firstName ? getUserFullName({ firstName, lastName, suffix }, true) : companyName}
          </span>
          <StatusLabel status={customerType} />
        </Grid>
        {address && (
          <Grid {...defaultGridItemProps} className={styles.location}>
            <LocationOnOutlined />
            <span>
              {isUSSelected(address.country) || isCanadaSelected(address.country)
                ? `${address.city}, ${address.stateRegion}`
                : `${address.city}, ${address.country}`}
            </span>
          </Grid>
        )}
        <Divider className={styles.divider} />
        <Grid {...defaultGridItemProps} className={styles.info}>
          {(title || companyName) && customerType === MemberDirectoryCustomerType.Individual && (
            <div className={styles.row}>
              <Domain className={styles.icon} />
              <span>
                {companyName && title ? `${title} at ` : title}
                {parentCompanyId ? (
                  <Link
                    underline={'always'}
                    color={'primary'}
                    target={'_blank'}
                    href={`${getHashRouteUrl(routes.customerCompany)}/${parentCompanyId}`}
                  >
                    {companyName}
                  </Link>
                ) : (
                  companyName
                )}
              </span>
            </div>
          )}
          {website && (
            <div className={styles.row}>
              <Language className={styles.icon} />
              <Website link={website} />
            </div>
          )}
          {!isContactInformationPrivate && (
            <>
              {phone && (
                <div className={styles.row}>
                  <Phone className={styles.icon} />
                  {getPhoneLink(phone)}
                </div>
              )}
              {email && (
                <div className={styles.row}>
                  <Email className={styles.icon} />
                  {getEmailLink(email)}
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid {...defaultGridItemProps}>
          {marketSegments?.map((segment) => (
            <StatusLabel key={segment} className={styles.segmentLabel} status={segment} variant={'outlined'} />
          ))}
        </Grid>
      </Grid>
      <Button color={'primary'} variant={'outlined'} target={'_blank'} href={getDetailsLink(customerType, id)}>
        {'View Details'}
      </Button>
    </Card>
  );
};

export default MemberListItem;
