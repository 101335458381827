import { Checkbox, Grid, InputAdornment, ListItemText, MenuItem, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getRequiredValidationRule, getValidationProps } from 'util/Form';
import useRequest from 'hooks/useRequest';
import { defaultGridItemProps, getInputLoadingProps } from 'util/Layout';
import UserService from 'services/api/UserService';
import Country, { State } from 'store/types/Country';
import { Search } from 'react-feather';

import styles from './CountryFormItem.module.scss';
import commonStyles from 'styles/common.module.scss';

interface CountryFormItemProps {
  countryFieldName?: string;
  statesFieldName?: string;
  required?: boolean;
}

const getSelectedLabel = (value: string[] = [], stateList: State[] = []): React.ReactNode =>
  value.length ? (
    stateList
      .filter(({ code }) => value.includes(code))
      .map(({ name }) => name)
      .join(', ')
  ) : (
    <span>{'All States/Provinces'}</span>
  );

const CountryFormItem: React.FunctionComponent<CountryFormItemProps> = ({
  countryFieldName = 'country',
  statesFieldName = 'states',
  required,
}) => {
  const { data, loading, error } = useRequest<Country[]>(UserService.getCountries);
  const [options, setOptions] = useState<Country[]>([]);
  const {
    control,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext();
  const countryValue = useWatch({ control, name: countryFieldName });
  const stateOptions = useMemo(
    () => options.find(({ name }) => countryValue === name)?.states || [],
    [options, countryValue]
  );

  useEffect(() => {
    if (countryValue && isDirty) {
      setValue(statesFieldName, []);
    }
  }, [options, setValue, countryValue, isDirty, statesFieldName]);

  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data, setValue]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridItemProps} md={3}>
        <span className={styles.title}>{'Country'}</span>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps(countryFieldName, errors)}
              InputProps={
                loading
                  ? { ...getInputLoadingProps(loading), className: styles.input }
                  : {
                      className: styles.input,
                      startAdornment: (
                        <InputAdornment position={'start'}>
                          <Search />
                        </InputAdornment>
                      ),
                    }
              }
              className={styles.input}
              disabled={loading}
              required={required}
              size="small"
              select={true}
              SelectProps={{
                displayEmpty: true,
                renderValue: (selectedId: any) =>
                  options.find(({ name }) => selectedId === name)?.name || <span>{'All Countries'}</span>,
              }}
            >
              <MenuItem value={''}>
                <ListItemText primary={'All Countries'} />
              </MenuItem>
              {options.map(({ name, id }: Country) => (
                <MenuItem value={name} key={`country-${id}`}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </TextField>
          )}
          name={countryFieldName}
          control={control}
          rules={{ required: getRequiredValidationRule(countryFieldName, true, required) }}
        />
      </Grid>
      {!!stateOptions.length && (
        <Grid {...defaultGridItemProps} md={3}>
          <span className={styles.title}>{'State/Province'}</span>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                {...getValidationProps(statesFieldName, errors)}
                InputProps={
                  loading
                    ? { ...getInputLoadingProps(loading), className: styles.input }
                    : {
                        className: styles.input,
                        startAdornment: (
                          <InputAdornment position={'start'}>
                            <Search />
                          </InputAdornment>
                        ),
                      }
                }
                className={styles.input}
                placeholder={'Select State/Province'}
                disabled={loading}
                required={required}
                size="small"
                select={true}
                SelectProps={{
                  displayEmpty: true,
                  multiple: true,
                  renderValue: (selected: any) => getSelectedLabel(selected || [], stateOptions),
                }}
              >
                {stateOptions.map(({ code, id, name }: Country) => (
                  <MenuItem value={code} key={`state-${id}`}>
                    <Checkbox checked={field.value.includes(code)} color={'primary'} size={'small'} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </TextField>
            )}
            name={statesFieldName}
            control={control}
            rules={{ required: getRequiredValidationRule(statesFieldName, true, required) }}
          />
        </Grid>
      )}
    </>
  );
};
export default CountryFormItem;
